import {FeeAnalysisTabs, FeeAnalysisViewTypeTabs} from '../types';

export const fundListSwitchOptions = [
  {
    id: 'table',
    value: 'table',
    icon: 'list',
  },
  {
    id: 'tiles',
    value: 'tiles',
    icon: 'tiles',
  },
];

export const fundListSwitchOptionsForActiveCompanies = [
  ...fundListSwitchOptions,
  {
    id: 'bar-chart',
    value: 'barChart',
    icon: 'bar-chart-filled',
  },
  {
    id: 'treemap-chart',
    value: 'treemapChart',
    icon: 'treemap',
  },
  {
    id: 'scattered-bubbles',
    value: 'scatteredBubbles',
    icon: 'scattered-bubbles',
  },
];

export const feeAnalysisTabsViewOptions = [
  {
    id: 'graph-chart',
    value: FeeAnalysisViewTypeTabs.ChartFeesView,
    icon: 'graph-chart',
  },
  {
    id: 'table',
    value: FeeAnalysisViewTypeTabs.TableFeesView,
    icon: 'table',
  },
];

export const feeAnalysisTabsOptions = [
  {
    id: FeeAnalysisTabs.FeeTypes,
    label: 'Funds.FeeAnalysisDashboard.SegmentedControl.FeeTypes',
    value: FeeAnalysisTabs.FeeTypes,
  },
  {
    id: FeeAnalysisTabs.SupplierFees,
    label: 'Global.Suppliers',
    value: FeeAnalysisTabs.SupplierFees,
  },
];

export const fundExposureSortOptions = [
  [
    {id: 'alpha-asc', label: 'Global.Sorting.A-Z', value: 'alpha-asc'},
    {id: 'alpha-desc', label: 'Global.Sorting.Z-A', value: 'alpha-desc'},
  ],
  [
    {id: 'value-asc', label: 'Global.Sorting.ValueAsc', value: 'value-asc'},
    {id: 'value-desc', label: 'Global.Sorting.ValueDesc', value: 'value-desc'},
  ],
];
